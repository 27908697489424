.fixedDataTableRowLayout_rowWrapper .public_fixedDataTableCell_main {
  transition-duration: .2s;
}

.hoverHighlight:hover .public_fixedDataTableCell_main {
  cursor: pointer;
  background-color: #fafafa;
}

.public_fixedDataTableRow_highlighted, .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
  background-color: #fff;
}

.public_fixedDataTable_header, .public_fixedDataTable_header .public_fixedDataTableCell_main {
  background: #fff;
  font-size: 16px;
  font-weight: 400;
}

.fixedDataTableLayout_main {
  border: 0;
  font-family: "system-ui", Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.fixedDataTableCellLayout_main {
  border-width: 0 0 1px;
  border-bottom-style: solid;
  border-bottom-color: #ebebeb;
}

.fixedDataTableLayout_header {
  border-color: #ebebeb;
}

.fixedDataTableCellLayout_wrap1 {
  height: 100%;
}
/*# sourceMappingURL=index.8dbb4b70.css.map */

/*
 * Padding above table
 */
.public_fixedDataTable_main {

}

.fixedDataTableRowLayout_rowWrapper .public_fixedDataTableCell_main {
  -webkit-transition-duration: .2s; /* Safari */
  transition-duration: .2s;
}

.hoverHighlight:hover .public_fixedDataTableCell_main {
  background-color: #fafafa;
  cursor: pointer;
}

/*
 * No alt highlighted rows
 */
.public_fixedDataTableRow_highlighted,
.public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
  background-color: white;
}

/*
 * No header background image
 */
.public_fixedDataTable_header,
.public_fixedDataTable_header .public_fixedDataTableCell_main {
  background: white;
  font-weight: 400;
  font-size: 16px;
}

/*
 * No borders on table
 */
.fixedDataTableLayout_main {
  border: 0;
  font-size: 14px;
  font-family: "system-ui", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/*
 * Only bottom border on cells
 */
.fixedDataTableCellLayout_main {
  border-width: 0;
  border-bottom: 1px solid #ebebeb;
}

.fixedDataTableLayout_header {
  border-color: #ebebeb;
}

/*
 * Center align content in cells
 */
.fixedDataTableCellLayout_wrap1 {
  height: 100%;
}
